import React from 'react';
import Headerr from './components/header.js';
import { Layout } from 'antd';
import './App.css'
import PricingPlans from './PricingPlans.js';
import { Routes, Route } from 'react-router-dom';
import SignUp from './components/signup.js';
import Login from './components/login.js';
import Admin from './components/admin.js';
import CheckoutPage from './components/checkout.js';
import SuccessPage from './components/success.js';
import FailurePage from './components/failure.js';
import Footer from './components/footer.js';
import PrivacyPolicy from './components/privacy.js';
import TermsOfService from './components/terms.js';
import ResetPassword from './components/ResetPassword.js';
import UpdatePassword from './components/UpdatePassword.js';


const App = () => {
  return (
    <div>
      <Headerr></Headerr>
      <Routes>
      <Route exact path="/" element={<PricingPlans />} />
        <Route exact path="/prices" element={<PricingPlans />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-of-service" element={<TermsOfService />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/checkout" element={<CheckoutPage/>} />
        <Route exact path="/success" element={<SuccessPage></SuccessPage>} />
        <Route exact path="/failure" element={<FailurePage></FailurePage>} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/update-password/:token" element={<UpdatePassword />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
};

export default App;
