import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, notification } from "antd";
const { Title, Text } = Typography;

const UpdatePassword = () => {
  const [newPass, setnewPass] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (newPass !== confirmPassword) {
      notification.error({
        message: "Passwords do not match",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/update-password`,
        {
          token,
          newPass,
        }
      );
      notification.success({
        message: "Password updated successfully!",
        description: response.data,
      });
      navigate("/login");
    } catch (error) {
      const errorMessage = error.response?.data || "Something went wrong";
      if (error.response?.status === 401) {
        notification.error({
          message: "Reset Link Expired",
          description:
            "Your reset password link has expired. Please request a new one.",
        });
      } else {
        notification.error({
          message: "Error updating password",
          description: errorMessage.message || errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "40px",
            borderRadius: "10px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <Title
            level={3}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Update Password
          </Title>

          <Text style={{ display: "block", marginBottom: "10px" }}>
            Enter your new password below:
          </Text>

          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                value={newPass}
                onChange={(e) => setnewPass(e.target.value)}
                placeholder="Enter your password"
                style={{ padding: "10px" }}
                required
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirm-password"
              rules={[
                { required: true, message: "Please confirm your password!" },
              ]}
            >
              <Input.Password
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
                style={{ padding: "10px" }}
                required
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  width: "100%",
                  padding: "10px 0",
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                {loading ? "Updating..." : "Update Password"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
