import React,{useState} from "react";
import { Form, Input, Button, Typography,notification } from "antd";
import axios from 'axios';


const { Title, Text } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const resetPassword = async () => {
    setLoading(true);
    try {
      await axios.post('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/reset-password', {
        email: email, 
      });
      notification.success({
        message: 'Success',
        description: 'Mail sent successfully!',
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: error.response?.data?.error || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff", 
            padding: "40px",
            borderRadius: "10px", 
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", 
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <Title level={3} style={{ textAlign: "center", marginBottom: "20px" }}>
            Reset Password
          </Title>

          <Text style={{ display: "block", marginBottom: "10px" }}>
            Enter your email below to receive password reset URL:
          </Text>
          <Form layout="vertical">
          <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your Email!" }]}
      >
        <Input
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
          placeholder="Enter your email"
          style={{ padding: "10px" }}
        />
      </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={resetPassword}
                loading={loading}
                style={{
                  width: "100%",
                  padding: "10px 0", 
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
